import PropTypes from 'prop-types';
import ColumnsWrapper from 'libs/storyblok/content/Modules/ColumnsWrapper';
import { columnsHeadingFontKeys } from 'config/branding/columns-header-font-keys';
import CmsDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock';
import { transformButton } from '@activebrands/core-web/libs/storyblok/utils/transform-buttons';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import getPositionStyles from '@activebrands/core-web/utils/style-functions/get-position-styles';
import TopPaginationSlider from 'components/Header/Navigation/TopPaginationSlider';
import { getCollapsibleColumnStyles } from './collapsible-styles';

const FourColumnsModule = ({ content = {}, isGrid = false }) => {
    const [css] = useStyletron();
    const { button = [], columns, heading = [], headingStyle = 'md', verticalAlignment } = content;

    const columnsLayoutPositionStyles = getPositionStyles({ contentVerticalAlignment: verticalAlignment });

    const headingSettings = transformHeading({
        type: heading?.[0]?.type,
        defaultType: 'h3',
    });

    const transformedButton = button?.[0] ? transformButton(button?.[0]) : {};

    return (
        <div
            className={css({
                marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)',
                borderRadius: 'var(--border-radius-module)',
                overflow: 'hidden',
            })}
        >
            <TopPaginationSlider
                isDraggable
                $paginationStyle={{ display: 'none' }}
                $paginationWrapperStyle={{ display: 'none' }}
                // as the child component receiving these styles is potentially overflowing the x-axis, justify-content will not work
                $sliderStyle={{ ...columnsLayoutPositionStyles, justifyContent: 'auto' }}
                button={transformedButton}
                heading={heading?.length > 0 && heading?.[0]?.text}
                headingFontKeys={columnsHeadingFontKeys[headingStyle]}
                headingStyle={{ padding: '0 var(--margin-content)' }}
                headingType={headingSettings.type}
                slidesPerView={[1.5, null, 2.5, null, 4]}
                spaceBetween={['12px', null, null, '16px']}
            >
                {columns.map((column, i) => (
                    <ColumnsWrapper
                        $style={{
                            borderRadius: 'var(--border-radius-module)',
                            overflow: 'hidden',
                            ...getCollapsibleColumnStyles(column.component),
                        }}
                        component={column.component}
                        key={column._uid}
                        numberOfItems={columns.length}
                        position={i}
                        verticalAlignment={verticalAlignment}
                    >
                        <CmsDataTransformerBlock isFourColumns block={column} />
                    </ColumnsWrapper>
                ))}
            </TopPaginationSlider>
        </div>
    );
};

FourColumnsModule.propTypes = {
    content: PropTypes.object,
    isGrid: PropTypes.bool,
};

export default FourColumnsModule;
